import React from 'react';

const PhoneIcon = ({  }) => {
  const telephoneNumber = '+33175361752'; 

  return (
    <div style={window.innerWidth <= 768 ? styles.phoneIconMobile : styles.phoneIcon}>
      <p><b>Contactez-nous au :</b>
      {window.innerWidth <= 768 ? <br/> : <></>}
      📞 <a style={styles.header} href={`tel:${telephoneNumber}`}>01 75 36 17 52</a>
      </p>
    </div>
  );
};

const styles = {
  phoneIcon: {
    fontSize: '25px',
    paddingTop: '3%',
  },
  phoneIconMobile: {
    display: 'flex',
    flexDirectiion: 'column',
    fontSize: '28px',
    paddingTop: '2%',
  },
  header: {
    background: '#162adb',
    color: 'white',
    fontSize: '3.2vh',
    borderRadius: '10px',
    margin: '5px',
    padding: '2px'
  },
  logo: {
    height: 120,
    paddingTop: '4vh'
  }
}


export default PhoneIcon;
