import React, { useEffect, useState } from 'react'
import LandingPage from './LandingPage'

const App: React.FC = () => {
  
  return (
    <LandingPage/>
  )
}

export default App;
